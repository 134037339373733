export default[
    {
        header: 'Digest'
    },
    {
        title: 'Events',
        route: 'violations',
        icon: 'fa-bolt',
        roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
      },
      {
        title: 'Insights',
        route: 'insights',
        icon: 'fa-chart-simple',
        roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
    
      },
]