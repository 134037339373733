<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <!-- <feather-icon
        v-bind:badge="getNotificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      /> -->
        <span class="fa-layers fa-fw" style="margin-top:6px">
       <font-awesome-icon 
       icon="fa-regular fa-bell"
       class="text-body" 
      style="font-size:24px"
       />
       <span v-if="getNotificationCount>0" style="position:absolute; top:-10px; right:-8px">
       <span class="fa-layers-counter" style="background:Tomato; font-size:36px;">{{getNotificationCount}}</span></span>
        </span>

    </template>
    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge pill variant="light-primary">
          {{ getNotificationCount }} New Notifications
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar class="scrollable-container media-list scroll-area">
      <!-- Account Notification -->
      <!-- I will remove the keys for notification as we dont need it to be reactive here is example
      <b-link
        v-for="notification in notifications"
        v-bind:key="notification.id"
      >
       -->
      <b-link
        v-for="notification in notifications"
        @click="notificationClicked(notification)"
      >
        <b-media v-if="notification.message_heading !== 'Event Detected'">
          <template #aside>
            <b-avatar
              size="30"
              v-if="notification.message_heading == 'Mentions'"
              variant="info"
            >
              <feather-icon
                v-if="notification.message_heading == 'Mentions'"
                icon="MessageCircleIcon"
              />
            </b-avatar>

            <b-avatar
              size="30"
              v-if="notification.message_heading == 'Stakeholder Added'"
              variant="success"
            >
              <feather-icon
                v-if="notification.message_heading == 'Mentions'"
                icon="UsersIcon"
              />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.message_heading }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message }}</small>
        </b-media>
      </b-link>
      <!-- System Notification Toggler -->
      <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          Event Notifications
        </h6>
        <b-form-checkbox v-model="checked" name="check-button" switch />
      </div>

      <!-- Event Notifications -->
      <b-link
        v-for="notification in notifications"
        @click="notificationClicked(notification)"
      >
        <b-media
          v-if="notification.message_heading == 'Event Detected' && checked"
        >
          <template #aside>
            <b-avatar
              size="30"
              v-if="notification.message_heading == 'Event Detected'"
              variant="warning"
            >
              <feather-icon
                v-if="notification.message_heading == 'Event Detected'"
                icon="ZapIcon"
              />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.message_heading }}
            </span>
          </p>
          <small class="notification-text">{{ notification.message }}</small>
        </b-media>
      </b-link>

      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-if="notifications != 0"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotifications()"
        >Clear all notifications
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import EventBus from "@/event-bus.js";
import READ_ALL_NOTIFICATIONS_FOR_USER from "@/graphql/notification/mutation/ReadAllNotificationsForUser.gql";
import axios from "axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox
  },
  props: {
    notifications: Array
  },
  directives: {
    Ripple
  },

  data() {
    return {
      // notifications: [],
      renderedNotifications: [],
      updateEvery: [],
      checked: true
    };
  },

  computed: {
    getNotifications() {
      return this.notifications;
    },
    getNotificationCount() {
      let count = 0;
      if (this.checked === true) {
        count = this.notifications.length;
      } else {
        count = this.notifications.filter(
          notification => notification.message_heading !== "Event Detected"
        ).length;
      }
      return count;
    }
  },
  mounted() {
    EventBus.$on("notify", data => {});
  },
  methods: {
    notificationClicked(notification) {
      if (notification.message_heading == "Mentions") {
        let eventId = notification.message.split("#")[1].trim();
        if (this.$route.name == "violations") {
          EventBus.$emit("setEventIdOnNotificationClick", eventId);
        } else {
          this.$router.replace({
            name: "violations",
            params: { searchQueryIdProp: eventId }
          });
        }
      } else {
        this.$router.replace({ name: "violations" });
      }
    },
    readAllNotifications() {
      this.$apollo
        .mutate({
          mutation: READ_ALL_NOTIFICATIONS_FOR_USER,
          variables: {
            recepient_id: localStorage.getItem("userId")
          }
        })
        .then(data => {});
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    };

    return {
      // notifications,
      perfectScrollbarSettings
    };
  }
};
</script>

<style></style>
