<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        style="color:#29335C !important"
        class="ml-25"
        href="https://1.envato.market/pixinvent_portfolio"
        target="_blank"
      ><b>Protex AI</b></b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block">Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
      in Limerick
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>

<style>

.stroke-current{
  margin-left:3px !important;
  margin-right:3px;
}

</style>
