export default[
    {
        header: 'Workspace'
    },
    {
        title: 'Story Board',
        route: 'workspace',
        icon: 'fa-window-restore',
        roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin'],
      },
      // {
      //   title: 'Actions',
      //   route: 'actions',
      //   icon: 'fa-square-check',
      //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin'],
      // },
      {
        title: 'Reporting',
        route: 'reporting',
        icon: 'fa-file-lines',
        roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
      },
]