export default [ 
  // {
  //   title: 'Users',
  //   route: 'admin-users-list',
  //   icon: 'fa-user',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  // },
    {
    title: 'Help',
    route: 'admin-users-list',
    icon: 'fa-circle-question',
    roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  },
  // {
  //   title: 'Hub',
  //   route: 'hub',
  //   icon: 'MessageSquareIcon',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']
  // },
  // {
  //   title: 'Product Portal',
  //   route: 'product-portal',
  //   icon: 'CompassIcon',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  // },
  // {
  //   title: 'Audit Manager',
  //   route: 'audit-manager',
  //   icon: 'ArchiveIcon',
  //   roles:['admin','Staff', 'SiteAdmin', 'CompanyAdmin']

  // },
  {
    title: 'Profile',
    route: 'access-control',
    icon: 'UserIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Support',
    href: 'https://pixinvent.ticksy.com/',
    icon: 'LifeBuoyIcon',
  },
  {
    title: 'Docs',
    href: 'https://pixinvent.com/demo/Protex AI-vuejs-admin-dashboard-template/documentation',
    icon: 'FileTextIcon',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
  },
]
