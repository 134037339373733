<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name  mb-0">
          <b>{{getUserName()}}</b> - <b>{{getUserEmail()}}</b>
        </p>
        <span class="user-status">{{ user ? formatRole(user.role) : "" }}</span>
      </div>
      <b-avatar
        v-if="user && user.profile_pic_url"
        size="40"
        :src="siteUsersProfilePics[user.profile_pic_url]"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      </b-avatar>
      <b-avatar
        v-else
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >

      </b-avatar>
    </template>


    <b-dropdown-item link-class="d-flex align-items-center" @click="AwsSignOut">
      <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  VBPopover,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";
import SET_LOGIN_STATUS from "@/graphql/profile/mutation/SetLoginStatus.gql";
import EventBus from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";
import {
  getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage,
  getUserEmail,
} from "@/auth/utils";

const API_ENDPOINT = process.env.VUE_APP_LAMBDA_PRESIGNED_PROFILE_IMG;

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VBPopover,
  },
  data() {
    return {
      changeLogUrl:
        "https://app-protex-ai.notion.site/Change-Log-3aca2c2759d94b4bbe7fbc875a4ad327",
      avatarText,
    };
  },

  directives: {
    "b-popover": VBPopover,
  },

  setup() {
    return {
      getUserRole,
     
      getUserRole,
  getUserSiteId,
  getUserId,
  getUserName,
  getUserProfileImage,
  getUserEmail
    };
  },
  computed: {
    ...mapGetters({
      siteUsersProfilePics: "profilePics/getSiteUsersProfilePics",
      user: "userSettings/getUserInfo",
    }),
  },
  watch: {
    user(newVal){
      if(newVal.profile_pic_url){
        this.fetchSiteUserProfilePics(newVal.profile_pic_url)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchSiteUserProfilePics: "profilePics/fetchSiteUserProfilePics",
    }),
    formatRole(role) {
      if (role == "SiteAdmin") {
        return "Site Admin";
      } else if (role == "CompanyAdmin") {
        return "Company Admin";
      } else if (role == "SuperUser") {
        return "Super User";
      } else if (role == "Staff") {
        return "Site User";
      } else if (role == "admin") {
        return "Protex Admin";
      } else {
        return role;
      }
    },

    gotoChangeLog() {
      window.location.href = this.changeLogUrl;
    },
    async AwsSignOut() {
      try {
        this.$apollo
          .mutate({
            mutation: SET_LOGIN_STATUS,
            variables: {
              user_id: getUserId(),
              login_status: 0,
              last_seen: new Date(),
            },
          })
          .then((data) => {
            localStorage.clear();

            // Reset ability
            this.$ability.update(initialAbility);
          })
          .catch((e) => {
            console.log("ERROR HERE", e);
          });
        await Auth.signOut({ global: true }).then(() => {
          // Redirect to login page
          localStorage.clear();
          this.$router.push({ name: "auth-login" });
        });
      } catch (error) {
        console.log(error);
        // General error due to token revoke in that case follow normal SignOut
        console.log("Tokens revoked Executing normal Signout");
        if (error.code === "NotAuthorizedException") {
          await Auth.signOut().then(() => {
            localStorage.clear();
            // Reset ability
            this.$ability.update(initialAbility);
            // Redirect to login page
            this.$router.push({ name: "auth-login" });
          });
        }
      }
    },
  },
};
</script>
