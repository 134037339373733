
export default [
    {
        header: 'Configure',
    },


    {
        title: 'Cameras',
        route: 'cameras',
        icon: 'fa-video',
        roles: ['admin', 'Staff', 'SiteAdmin', 'CompanyAdmin']
    },
    {
        title: 'Rules',
        route: 'rules',
        icon: 'fa-diagram-project',
        roles: ['admin', 'Staff', 'SiteAdmin', 'CompanyAdmin']
    }
]