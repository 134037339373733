export default [ 
  {
    title: 'Protex Admin',
    route: 'superuser',
    icon: 'UserPlusIcon',
    roles: ['SuperUser']
  },
  {
    title: 'Create Company',
    route: 'createcompany',
    icon: 'KeyIcon',
    roles: ['SuperUser']
  },
  {
    title: 'Handle Site Assignment',
    route: 'siteassignment',
    icon: 'FileIcon',
    roles: ['SuperUser']
  }
]
